import React from "react"

import Layout from "../layouts/layout"

import Breadcrumb from "../components/breadcrumb"

function Policy() {
    return(
        <Layout>
            <div className="mt-3">
                <Breadcrumb title="Policy" />
            </div>
            <h1 className="title">Policy</h1>

            <h3>運営者情報</h3>
            <ul>
                <li>運営者：sn42</li>
                <li>連絡先：sn42_blog(at)outlook.jp</li>
            </ul>

            <h3>制定日・最終改定日</h3>
            <ul>
                <li>制定日：2020年5月30日</li>
                <li>最終改定日：2020年5月30日</li>
            </ul>

            <h3>当サイトの情報の正確性について</h3>
            <ul>
                <li>当サイトの情報においては、可能な限り正確な情報やソースを掲載するよう努めています。</li>
                <li>必ずしも正確性を保証するものではありませんので、正確性の高い情報を参照するようにお願いいたします。</li>
            </ul> 

            <h3>損害等の責任について</h3>
            <ul>
                <li>当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますので、ご了承ください。</li>
                <li>当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任も負いません。</li>
                <li>当サイトの保守、火災、停電、その他の自然災害、ウィルスや第三者の妨害等行為による不可抗力によって、当サイトによるサービスが停止したことに起因して利用者に生じた損害についても、何ら責任を負うものではありません。</li>
                <li>当サイトの利用は、自己責任でお願いいたします。</li>
            </ul>

            <h3>画像の著作権や肖像権等について</h3>
            <ul>
                <li>当サイトで掲載している画像の著作権や肖像権等は、各権利所有者に帰属いたします。</li>
                <li>当サイトに存在する、文章や画像、動画等の著作物の情報を無断転載することを禁止いたします。</li>
            </ul>

            <h3>リンクについて</h3>
            当サイトは基本的にリンクフリーですが、画像への直リンクや、インラインフレームの使用はお断りいたします。

        </Layout>
    )
}

export default Policy